/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages  


                // Search Product Anchor
                $('.btn-search-product').click(function () {
                    $('.product-search').toggleClass('open');
                });

                // Search Product Anchor
                $('.fa-search').click(function () {
                    $('.product-search-mobile').toggleClass('open');
                    $('.main-header').removeClass('fixed');
                    $('.main-header').addClass('close-this');
                });

                // Search Product Anchor
                $('.fa-window-close').click(function () {
                    $('.product-search-mobile').toggleClass('open');
                    $('.main-header').removeClass('close-this');
                });

                $('.owl-carousel').owlCarousel({
                    loop:false,
                    margin: 5,
                    nav:false,
                    dots: false,
                    //center:true,
                    autoWidth:true
                });


                var firstClick = true;  // Variable to track if it's the first click

                // Anchor scroll event
                $('a[href*="#"]:not([href="#"])').click(function(e) {
                    var target = $(this.hash);

                    if (target.length) { // Ensure target exists
                        var targetOffset = target.offset().top - 120;  // Get the offset value

                        // Check if it's the first click and the header is not yet fixed
                        if (firstClick) {
                            var headerHeight = $('header').outerHeight();  // Get the header height
                            targetOffset -= headerHeight;  // Adjust for header height on first click
                            firstClick = false;  // Set to false after the first click
                        }

                        //alert('Anchor Scroll Target Offset: ' + targetOffset);  // Check the offset
                        $('html,body').stop().animate({
                            scrollTop: targetOffset
                        }, 'linear');
                    }
                });

                // On window load
                $(window).on('load', function() {
                    if (location.hash) {
                        var id = $(location.hash);
                        if (id.length) { // Ensure the id exists
                            $('html,body').animate({
                                scrollTop: id.offset().top - 120
                            }, 'linear');
                        }
                    }
                });

                
                


                //Start the EqualHeight Function 
                function updateViewportDimensions() {
                    var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
                    return { width:x,height:y };
                }
                // setting the viewport width
                var viewport = updateViewportDimensions();

                var waitForFinalEvent = (function () {
                    var timers = {};
                    return function (callback, ms, uniqueId) {
                        if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
                        if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
                        timers[uniqueId] = setTimeout(callback, ms);
                    };
                })();
                
                // how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
                var timeToWaitForLast = 100;
                
                /*
                 * Set the same height for elements
                */
                $.fn.equalHeights = function(per_row) {
                    var currentTallest  = 0,
                        $this           = $(this);

                    function mapCallback(item) {
                        currentTallest  = 0;
                        $(this).css({'min-height': '1px'});
                        if ($(this).outerHeight() > currentTallest) {
                            currentTallest = $(this).outerHeight();
                        } 
                        return {
                            currentTallest : item[0],
                        };
                    } 

                    if (per_row) {
                        for (var i=0; i<$this.length; i=i+per_row) {
                            currentTallest  = 0;
                            $this.slice(i, i+per_row).each(mapCallback);
                            $this.slice(i, i+per_row).css({'min-height': currentTallest});
                        }
                    } else {
                        $this.each(function(){
                            $(this).css({'min-height': '1px'});
                            if ($(this).outerHeight() > currentTallest) {
                                currentTallest = $(this).outerHeight();
                            }
                        });
                    
                        $this.css({'min-height': currentTallest});
                    }
                    return this;
                };

                /*
                 * Put all your regular jQuery in here.
                */
                $(document).ready(function($) {
                   // Make elements the same min-height
                   $('#anchor').equalHeights();
                }); /* end of as page load scripts */
                
                $(window).load(function() {
                    // Make elements the same min-height 
                    $('#anchor').equalHeights();
                });
                
                // Fire needed update on window resize
                $(window).resize(function () {
                    waitForFinalEvent( function() {
                        $('#anchor').equalHeights();  
                    }, timeToWaitForLast, "homepage_update_fn");
                });

                
                $('#menu-toggle').click(function () {
                    $(this).toggleClass('open');
                });

                $('.pushy ul li a').click(function () {
                    $(this).toggleClass('open');
                    $(this).next().toggleClass('open');
                });


                var stickyOffset = $(".content-product-category.wp-block-columns").offset();
                if (stickyOffset) {
                    var stickyTop = stickyOffset.top; // Now you can safely access .top
                } 

                //var stickyOffset = $(".content-product-category.wp-block-columns").offset().top;
                var ProductScrollStart = 0;
                $(document).scroll(function() {
                    ProductScrollStart = $(this).scrollTop();

                    if (ProductScrollStart >= stickyTop) {   
                        $(".content-product-category.wp-block-columns").addClass("fixed");
                    } else {
                        $(".content-product-category.wp-block-columns").removeClass("fixed");
                    }
                    
                });


                var nScrollStartMenu = 0;
                $(document).scroll(function() {
                    nScrollStartMenu = $(this).scrollTop();
                    if (nScrollStartMenu >= 1) {
                        $(".main-header").addClass("fixed");
                    } else {
                        $(".main-header").removeClass("fixed");
                    }
                });



                // Detect scroll event
                $(document).scroll(function() {
                    // Loop through each section
                    $(".AnchorTarget").each(function() {
                        var sectionOffset = $(this).offset().top -120; // Get section position
                        var scrollPos = $(window).scrollTop();    // Get current scroll position
                        var sectionHeight = $(this).outerHeight(); 

                        // Check if the section is in view
                        var sectionId = $(this).attr('id');
                        if (scrollPos >= sectionOffset - 50 && scrollPos < sectionOffset + sectionHeight) {
                            $('.owl-carousel a[href="#' + sectionId + '"]').addClass('active');  // Add active class to anchor
                        } else {
                            $('.owl-carousel a[href="#' + sectionId + '"]').removeClass('active');  // Remove active class if out of view
                        }
                    });
                });











            }, 
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        }, 
        // Contact us page, note the change from about-us to contact_us.
        'contact': {
            init: function() {
                
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
